/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connectSocketIO } from "../../utils/socket";
import useBlockBackButton from "../../utils/preventBrowserBack";
import { handleGTM } from "../../utils/helper";
import "./game.css";

let timeInterval;

const GamePage = () => {
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [socketData, setSocketData] = useState(null);
  const [showStart, setShowStart] = useState(false);
  const [showEnded, setShowEnded] = useState(false);
  const [removeTimer, setRemoveTimer] = useState(false);
  const [showDragon, setShowDragon] = useState(false);
  const [animateDragon, setAnimateDragon] = useState(false);
  const [showAscCode, setShowAscCode] = useState(true);

  useBlockBackButton();

  const setupSocket = useCallback(() => {
    const ws = connectSocketIO();

    ws.on("connect", () => {
      console.log("Connected to socket.IO server");
    });

    ws.on("message", (data) => {
      const wsData = JSON.parse(data);

      if (wsData?.startTurn) {
        ws.emit("home-message", "startTimer");
      }

      if (wsData?.event === "game-ended") {
        setShowEnded(true);
      }
      if (wsData?.goToHomePage) {
        console.log("Reconnecting socket.IO...");
        ws.disconnect();
        setupSocket(); // Attempt to reconnect
      }
      if (wsData?.waitTime && Number(wsData?.waitTime) > 0) {
        navigate("/wait");
      }
      if (data) {
        setSocketData(wsData);
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    setSocket(ws);

    return () => {
      if (ws) {
        ws.disconnect();
      }
    };
  }, [navigate]);

  useEffect(() => {
    const cleanupSocket = setupSocket();
    return cleanupSocket;
  }, [setupSocket]);

  useEffect(() => {
    let timer = 8;
    timeInterval = setInterval(() => {
      if (timer <= 1) {
        clearInterval(timeInterval);
        !animateDragon && handleAutoStart();
        handleGTM("NOTIFY_DEC_SWIPEUP_GAMEFRAME");
        timer = 0;
      }
      if (timer === 5) {
        setShowAscCode(false);
      }
      timer -= 1;
    }, 1000);
    return () => clearInterval(timeInterval);
  }, [socketData?.event === "startTimer"]);

  useEffect(() => {
    if (removeTimer || animateDragon) {
      clearInterval(timeInterval);
    }
  }, [removeTimer, animateDragon]);

  const handleSendMessage = useCallback(
    (message) => {
      if (socket) {
        socket.emit("home-message", message);
      }
    },
    [socket]
  );

  const startTurn = () => {
    handleSendMessage("startTurn");
    setShowStart(true);
    setTimeout(() => {
      setShowEnded(true);
    }, 13000);
  };

  const handlePlayNow = (tag) => {
    tag && handleGTM(tag);
    setShowDragon(true);
    setRemoveTimer(true);
    handleSendMessage("remove-timer");
  };

  const handleTryNow = useCallback(() => {
    handleGTM("ENGAGE_DEC_CTA_LEARNMORE");
    window.open("https://www.facebook.com/DomexPhilippines", "_blank");
  }, []);

  const handleSwipeUp = useCallback(
    (tag) => {
      tag && handleGTM(tag);
      setAnimateDragon(true);
      setTimeout(() => {
        setShowDragon(false);
        startTurn();
      }, 2000);
    },
    [startTurn]
  );

  const handleAutoStart = () => {
    handlePlayNow();
  };

  const renderDragon = () => (
    <div
      className="dragon-wrapper"
      onMouseDown={() => handleSwipeUp("ENGAGE_DEC_SWIPEUP_DRGON")}
      onTouchStart={() => handleSwipeUp("ENGAGE_DEC_SWIPEUP_DRGON")}
      role="presentation"
    >
      <img
        src="images/dragonFly.gif"
        alt="dragon fly domex"
        width={1272}
        height={618}
        className={animateDragon ? "animate-dragon" : ""}
      />
    </div>
  );

  return (
    <div className="game-page">
      <div className="header">
        <img className="logo-img" src="logo.png" alt="domex logo" />
      </div>
      {/* {!showEnded && renderTimer()} */}
      {showDragon && renderDragon()}
      {showAscCode ? (
        <img
          src="images/ascCode.jpg"
          alt="asc code domex"
          width={873}
          height={1600}
          className={showAscCode ? "show" : "hide"}
          style={{
            position: "relative",
            objectFit: "cover",
            width: "100vw",
            height: "100dvh",
            zIndex: "9999",
          }}
        />
      ) : null}
      <img
        src="images/swipeInstruction.png"
        alt="domex swipe"
        width={501}
        height={156}
        className={`swipe-instruction animate-bounce ${
          showStart ? "hide" : "show"
        }`}
      />

      <button
        className={`cta-btn ${showStart ? "hide" : "show"}`}
        onClick={() => handlePlayNow("ENGAGE_DEC_SWIPEUP_BUTTON")}
      >
        <img
          src="images/playNowBtn.png"
          alt="domex play now"
          width={247}
          height={73}
        />
      </button>

      <img
        src="images/killsGermText.png"
        alt="domex kill germs"
        width={605}
        height={160}
        className={`swipe-instruction ${showStart ? "show" : "hide"}`}
      />

      <button
        className={`cta-btn ${showEnded ? "show" : "hide"}`}
        onClick={handleTryNow}
      >
        <img
          src="images/learnMoreBtn.png"
          alt="domex learn more"
          width={247}
          height={73}
        />
      </button>
      <span className="footer-text">Creative Visualization</span>
      <img
        className="ascCode-img"
        src="images/ascCode.png"
        alt="asc code domex"
        width={233}
        height={15}
      />
    </div>
  );
};

export default GamePage;

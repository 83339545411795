import { useState, useEffect } from "react";

function useResponsiveScale() {
  const [state, setState] = useState({
    pageRatio: 0.6,
    scaleValue: 0,
    topValue: 0,
  });

  const customDebounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    const handleResize = customDebounce(() => {
      const currentScreenSize = window["innerHeight"];
      const newScaleValue = currentScreenSize / 19.2;
      const newTopValue = (100 - newScaleValue) / 2;

      setState((prev) => ({
        ...prev,
        scaleValue: newScaleValue,
        topValue: newTopValue,
      }));

      // Update page ratio
      setState((prev) => ({
        ...prev,
        pageRatio: window.innerHeight / window.innerWidth,
      }));
    }, 200); // Adjust the debounce delay as needed

    handleResize(); // Initial resize

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.pageRatio]);

  return state;
}

export default useResponsiveScale;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { connectSocketIO } from "../../utils/socket";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import "./home.css";

let timeInterval;

const HomePage = () => {
  const navigate = useNavigate();
  const gameRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [socketData, setSocketData] = useState(null);
  const [removeTimer, setRemoveTimer] = useState(false);
  const [revertAscCode, setRevertAscCode] = useState(false);

  useEffect(() => {
    const ws = connectSocketIO();
    setSocket(ws);

    ws.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    ws.on("message", (data) => {
      const wsData = JSON.parse(data);

      if (wsData) {
        setSocketData(wsData);
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    return () => {
      ws.disconnect();
    };
  }, []);

  const handleSendMessage = (message) => {
    if (!socket) return;
    if (message === "timeout") {
      setTimeout(() => {
        gameRef.current.play();
      }, 2000);
    }

    socket.emit("home-message", message);
  };

  useEffect(() => {
    let timer = 8;
    timeInterval = setInterval(() => {
      if (timer <= 1) {
        clearInterval(timeInterval);
        handleSendMessage("time-out");
        timer = 0;
      }
      timer -= 1;
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [socketData?.event === "startTimer"]);

  useEffect(() => {
    if (removeTimer) {
      clearInterval(timeInterval);
    }
  }, [removeTimer]);

  useEffect(() => {
    if (socketData?.event === "startTurn") {
      gameRef.current.play();
      setRemoveTimer(true);
      setTimeout(() => {
        setRevertAscCode(true);
      }, 12000);
    }

    if (socketData?.event === "remove-timer") {
      setRemoveTimer(true);
    }
  }, [socketData?.event]);

  return (
    <Layout>
      <video
        className={`home-domex-video show ${
          revertAscCode ? "revert-code" : ""
        }`}
        muted
        playsInline
        preload="auto"
        width={1080}
        height={1920}
        ref={gameRef}
        onEnded={() => {
          handleSendMessage("game-ended");
          navigate("/");
          setRevertAscCode(false);
        }}
      >
        {/* Use multiple formats for cross-browser support */}
        <source
          src={`${window.location.origin}/video/DOOH_Dregon.mp4`}
          type="video/mp4"
        />
        <source
          src={`${window.location.origin}/video/DOOH_Dregon.webm`}
          type="video/webm"
        />
        Your browser does not support the video tag.
      </video>
    </Layout>
  );
};

export default HomePage;

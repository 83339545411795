/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout";
import QRCode from "react-qr-code";
import { connectSocketIO } from "../utils/socket";

const LandingPage = () => {
  const navigate = useNavigate();
  const socketRef = useRef(null);
  const [socketData, setSocketData] = useState(null);

  const handleSocketMessage = useCallback((data) => {
    try {
      const wsData = JSON.parse(data);
      if (wsData) {
        setSocketData((prevData) => ({ ...prevData, ...wsData }));
      }
      if (wsData?.gamesPlayed) {
        localStorage.setItem("totalPlayed", wsData.gamesPlayed);
      }
    } catch (e) {
      console.error("Failed to parse message data:", e);
    }
  }, []);

  useEffect(() => {
    // Establish Socket.IO connection
    socketRef.current = connectSocketIO();

    const ws = socketRef.current;

    // Event listeners
    ws.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    ws.on("message", handleSocketMessage);

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    // Cleanup function to close Socket.IO connection and remove event listeners
    return () => {
      ws.off("message", handleSocketMessage);
      ws.disconnect();
    };
  }, [handleSocketMessage]);

  useEffect(() => {
    if (socketData?.goToHomePage) {
      navigate("/home");
    }
  }, [socketData?.goToHomePage, navigate]);

  return (
    <Layout>
      <div className="qr-main">
        <div className="qr-wrapper">
          <QRCode value={`${window?.location?.origin}/game`} />
        </div>
        <div className="home-content">
          <img
            src="images/domexText.png"
            alt="domex text"
            width={447}
            height={255}
          />
        </div>
      </div>
    </Layout>
  );
};

export default LandingPage;

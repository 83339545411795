import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing";
import HomePage from "./pages/home";
import GamePage from "./pages/game";
import WaitingScreen from "./pages/waiting";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/game" element={<GamePage />} />
          <Route exact path="/wait" element={<WaitingScreen />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

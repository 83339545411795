import { io } from "socket.io-client";

export const connectSocketIO = () => {
  // Determine the base URL based on the environment
  const baseURL =
    window.location.hostname === "localhost"
      ? "ws://localhost:3001"
      : `wss://domexclean.com`;

  // Construct the full URL to pass as a query parameter
  const fullURL = `${window.location.origin}${window.location.pathname}`;

  return io(baseURL, {
    query: { url: fullURL },
  });
};

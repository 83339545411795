import React from "react";
import useResponsiveScale from "../../utils/dynamicScreenScaling";
import "./layout.css";

const Layout = ({ children }) => {
  const { scaleValue, topValue } = useResponsiveScale();

  return (
    <div
      className="layout-wrapper"
      style={{
        transform: `translate(-50%, -${topValue}%) scale(${scaleValue / 100}, ${
          scaleValue / 100
        })`,
      }}
    >
      <div className="content">
        <div className="header">
          <img className="logo-img" src="logo.png" alt="domex logo" />
        </div>

        {children}
        <span className="footer-text">Creative Visualization</span>
        <img
          className="ascCode-img"
          src="images/ascCode.png"
          alt="asc code domex"
          width={233}
          height={15}
        />
      </div>
    </div>
  );
};

export default Layout;
